export default {
  data() {
    return {
      // 列表设置
      list: {
        url: '/canteen/accountLog',
        method: 'post',
        action: false,
        // 列表字段
        fields: this.getListFields()
      }
    };
  },
  methods: {
    getListFields() {
      return [
        { label: '姓名', prop: 'name', width: 160, headerAlign: 'center' },
        {
          label: '电话',
          prop: 'telephone',
          width: 160,
          headerAlign: 'center'
        },
        {
          label: '警号',
          prop: 'policeCard',
          width: 160,
          headerAlign: 'center'
        },
        {
          label: '补贴余额',
          prop: 'subsidyMoney',
          width: 140,
          align: 'right',
          headerAlign: 'center',
          formatter(row) {
            return `￥${row.subsidyMoney}`;
          }
        },
        {
          label: '现金余额',
          prop: 'balance',
          width: 140,
          align: 'right',
          headerAlign: 'center',
          formatter(row) {
            return `￥${row.balance}`;
          }
        },
        {
          label: '总余额',
          prop: 'totalBalance',
          width: 140,
          align: 'right',
          headerAlign: 'center',
          formatter(row) {
            return `￥${row.totalBalance}`;
          }
        }
      ];
    }
  }
};
