<template>
  <app-page :toolbar="toolbar" :list="list" @loaded="onLoaded">
    <template #append>
      <el-alert :title="balanceTitle" type="success" :closable="false" />
    </template>
  </app-page>
</template>

<script>
import mixins from './mixins';

export default {
  name: 'AccountBalance',
  mixins
};
</script>

<style lang="scss" scoped>
::v-deep .el-alert__title {
  font-size: 1.5em;
  line-height: inherit;
}
</style>
