import dayjs from 'dayjs';

export default {
  data() {
    return {
      // 工具栏设置
      toolbar: {
        size: 'small',
        labelWidth: '60px',
        // 搜索条件
        model: {
          name: undefined,
          date: dayjs()
            .subtract(1, 'd')
            .format('YYYY-MM-DD'),
          // canteenType: 1
          canteenType: Number(Number(sessionStorage.getItem('canteenType')) !== 999 ? sessionStorage.getItem('canteenType') : 1),

        },
        // 搜索条件字段
        fields: this.getToolbarFields(),
        // 导出功能
        downloader: {
          text: '导出',
          url: '/canteen/account/exportExcel',
          method: 'post'
        }
      }
    };
  },
  methods: {
    getToolbarFields() {
      return [
        {
          label: '姓名',
          prop: 'name',
          placeholder: '请输入要搜索的姓名',
          clearable: true
        },
        {
          label: '日期',
          prop: 'date',
          placeholder: '请选择要搜索的日期',
          type: 'date',
          clearable: true,
          valueFormat: 'yyyy-MM-dd',
          format: 'yyyy年MM月dd日',
          pickerOptions: { firstDayOfWeek: 1 }
        },
        {
          label: '食堂',
          prop: 'canteenType',
          placeholder: '请选择要搜索食堂',
          type: 'select',
          // clearable: true,
          options: [
            { label: '全部', value: undefined },
            { label: '一食堂', value: 1 },
            { label: '二食堂', value: 2 }
          ]
        }
      ];
    }
  }
};
