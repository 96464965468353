export default {
  data() {
    return {
      // 总余额
      balance: {
        total: 0,
        subsidy: 0,
        cash: 0
      }
    };
  },
  computed: {
    // 余额提示栏标题
    balanceTitle() {
      const { total, subsidy, cash } = this.balance;
      return `总余额:￥${total}，补贴总余额:￥${subsidy}，现金总余额:￥${cash}`;
    }
  },
  methods: {
    // 加载完成设置余额
    onLoaded(e) {
      const { other } = e;
      if (other) {
        // 总余额
        this.balance = {
          total: other.balanceTotal + other.subsidyMoneyTotal,
          subsidy: other.subsidyMoneyTotal,
          cash: other.balanceTotal
        };
      }
      if (sessionStorage.getItem('canteenType')){
        if (Number(sessionStorage.getItem('canteenType')) === 1){
          this.toolbar.fields[2].options = [{ label: '一食堂', value: 1 }]
        }else if (Number(sessionStorage.getItem('canteenType')) === 2){
          this.toolbar.fields[2].options = [{ label: '二食堂', value: 2 }]
        }
      }
    }
  }
};
